<template>
  <div class="gallery">
    <img v-for="(photo, index) in photos" :key="photo.src" class="gallery__item lazy-loaded-image lazy" :data-src="photo.src" @click="showModal(photo.src, photo.alt, index)">
    <PhotoModal 
      :screenHeight="screenHeight"
      :screenWidth="screenWidth"
      :isPhoneSize="isPhoneSize"
      :photoSrc="selectedPhotoSrc"
      :photoAlt="selectedPhotoAlt"
      :arrowRightIcon="arrowRightIcon"
      :cancelIcon="cancelIcon"
      :index="selectedPhotoIndex"
      :next="showNextPhoto"
      :closeModal="closeModal"/>
  </div>
</template>

<script>
import PhotoModal from "@/components/Modal/PhotoModal"
import { arrowRightIcon } from './../../assets/icons/arrow-right-icon.ts';
import { cancelIcon } from './../../assets/icons/cancel-icon.ts';

export default {
  name: 'gallery',
  components: {
    PhotoModal
  },
  data() {
    return {
      selectedPhotoSrc: null,
      selectedPhotoIndex: null,
      selectedPhotoAlt: null,
      photos: [
        { src: 'gallery/photo14.jpg', alt: 'Młoda para' },
        { src: 'gallery/photo15.jpg', alt: 'Sala weselna podjazd' },
        { src: 'gallery/photo16.jpg', alt: 'Młoda para na parkiecie' },
        { src: 'gallery/photo17.jpg', alt: 'Bukiet kwiatów' },
        { src: 'gallery/photo18.jpg', alt: 'Ślubna dekoracja' },
        { src: 'gallery/photo4.jpg', alt: 'Sala Weselna łódzkie' },
        { src: 'gallery/photo5.png', alt: 'Przytiwanie Pani młodej' },
        { src: 'gallery/photo6.png', alt: 'Sceneria weselna' },
        { src: 'gallery/photo7.png', alt: 'Pierwszy taniec' },
        ////{ src: 'gallery/photo8.jpg', alt: '' },
        ////{ src: 'gallery/photo9.jpg', alt: '' },
        ////{ src: 'gallery/photo10.jpg', alt: '' },
        { src: 'gallery/photo11.jpg', alt: 'Dekoracja stołów' },
      ],
      arrowRightIcon,
      cancelIcon,
      screenHeight: window.screen.height * .9,
      screenWidth: window.screen.width * 0.1,
      isPhoneSize: window.screen.width < 500,
    }
  },
  methods: {
    showModal(src, alt, index) {
      this.selectedPhotoSrc = src;
      this.selectedPhotoIndex = index;
      this.selectedPhotoAlt = alt;
      this.$modal.show('photo-modal');
    },
    showNextPhoto(index, isNext) {
      let newIndex = isNext 
        ? index >= this.photos.length - 1 ? 0 : index + 1
        : index <= 0 ? this.photos.length - 1 : index - 1;
      this.selectedPhotoSrc = this.photos[newIndex].src;
      this.selectedPhotoIndex = newIndex;
      this.selectedPhotoAlt = this.photos[newIndex].alt;
    },
    closeModal() {
      this.$modal.hide('photo-modal')
    }
  },
  mounted() {
    let lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));

    let lazyImageObserver = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove('lazy');
                lazyImageObserver.unobserve(lazyImage);
            }
        });
    });
    lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });

    window.addEventListener('keyup', (event) => {
      if (event.keyCode === 39) {
        this.showNextPhoto(this.selectedPhotoIndex, true)
      }
      if (event.keyCode === 37) {
        this.showNextPhoto(this.selectedPhotoIndex, false)
      }
    });
  }
}
</script>

<style lang="scss" scoped>
  .gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    @include respond(hd-desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include respond(tab-land) {
      gap: 15px;
    }
    @include respond(tab-port) {
      gap: 10px;
    }
    @include respond(phone) {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    &__item {
      height: 400px;
      width: 400px;
      background: $dark-orange;
      object-fit: cover;
      cursor: pointer;
      @include respond(tab-land) {
        height: 300px;
        width: 300px;
      }
      @include respond(tab-port) {
        height: 200px;
        width: 200px;
      }
      @include respond(phone) {
        height: 150px;
        width: 150px;
      }
    }
  }
</style>