<template>
  <div id="app" :class="{ 'app-fixed' : mobileMenuVisible }">
   <div id="nav">
      <Navbar @mobileMenuToggled="mobileMenuToggle"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar/Navbar.vue";

export default {
  name: "Grażka",
  data() { 
    return {
      mobileMenuVisible: false
    }
  },
  methods: {
    mobileMenuToggle(isVisible) {
      this.mobileMenuVisible = isVisible;
    } 
  },
  components: {
    Navbar
  }
};
</script>

<style lang="scss">
@import '@/assets/sass/main.scss';

</style>
