<template>
  <div class="app-section" :class="{ 'app-section--reversed': reversed }">
    <div class="app-section__image-container" v-show="imageSrc">
      <img class="app-section__image lazy-loaded-image lazy" :data-src="imageSrc2" :alt="imageAlt" ref="imageRef"/>
    </div>
    <div class="app-section__content" :class="{ 'app-section__content--full-width': !imageSrc2 }">
      <AppSectionContent :secondary="secondary" :title="contentTitle" :contentText="contentText">
        <slot></slot>
      </AppSectionContent>
    </div>
  </div>
</template>

<script>
import AppSectionContent from "@/components/Section/AppSectionContent"

export default {
  name: 'AppSection',
  data() {
    return {
      imageObserver: null
    }
  },
  mounted() {
    this.imageObserver = this.getNewIntersectionObserver('app-section__image--animation-right')
    this.imageObserver.observe(this.$refs['imageRef']);
  },
  methods: {
    getNewIntersectionObserver() {
      return new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const className = this.reversed ? 'app-section__image--animation-left' : 'app-section__image--animation-right'
            entry.target.classList.add(className);
            return;
          }
        });
      });
    }
  },
  components: {
    AppSectionContent
  },
  props: {
    imageSrc: {
      type: String,
      default: null
    },
    imageAlt: {
      type: String,
      default: ''
    },
    secondary: {
      type: Boolean,
      default: true
    },
    contentTitle: {
      type: String,
      default: ''
    },
    contentText: {
      type: String,
      default: ''
    },
    reversed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imageSrc2: function() {
      if (!this.imageSrc) {
        return '';
      }
      return this.imageSrc;
    }
  }
}
</script>

<style lang="scss" >

.app-section {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  background: #FAFAFA;
  @include respond(phone) {
    flex-direction: column;
  }
  &--reversed {
    flex-direction: row-reverse;
    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__image-container {
    width: 50%;
    background: $dark-orange;
    overflow: hidden;
    @include respond(phone) {
      width: 100%;
    }
   
  }
  &__image {
      position: relative;
      display: flex;
      width: 100%;
      //width: -webkit-fill-available;
      &--animation-right {
        animation: slideRight 2s 1;
      }
      &--animation-left {
        animation: slideLeft 2s 1;
      }
  }
  &__content {
    width: 50%;
    display: flex;
    align-items: center;
    @include respond(phone) {
      width: 100%;
    }
    &--full-width {
      width: 100%;
    }
  }
}

@keyframes slideRight {
  0% {
    left: -100vW;
  }
  100% {
    left: 0;
  }
}
@keyframes slideLeft {
  0% {
    right: -100vW;
  }
  100% {
    right: 0;
  }
}
</style>