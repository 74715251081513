<template>
    <nav class="navbar-menu">
        <div class="navbar-menu__mobile-icon" v-if="!mobileMenuVisible" v-html="hamburgerMenuIcon" @click="toggleMobileMenu"></div>
        <div class="navbar-menu__mobile-icon" v-else v-html="cancelIcon" @click="toggleMobileMenu"></div>
        <!--<NavbarMenuMobile :links="links" :isVisible="mobileMenuVisible"/>-->
        <div class="navbar-menu__links" :class="{ 'navbar-menu__links--visible': mobileMenuVisible }">
          <a class="navbar-menu__link" v-for="(link, index) in links" :key="index" :href="'#' + link.to" :class="link.to" @click="toggleMobileMenu">
              <div class="navbar-menu__separator"></div>
              <span class="active" >{{link.name}}</span>
          </a>
        </div>
    </nav>
</template>

<script>

//import NavbarMenuMobile from "@/components/Navbar/NavbarMenuMobile";
import { hamburgerMenuIcon } from "./../../assets/icons/hamburger-menu-icon.ts";
import { cancelIcon } from "./../../assets/icons/cancel-icon.ts";

export default {
  data() {
    return {
      mobileMenuVisible: false,
      hamburgerMenuIcon,
      cancelIcon,
      observer: null
    }
  },
  
  methods: {
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
      this.$emit('mobileMenuToggled', this.mobileMenuVisible);
    }
  },
  components: {
    //NavbarMenuMobile
  },
  props: {
    menuIcon: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
  .navbar-menu {
     z-index: 1;
    
    &__mobile-icon {
      display: none;
      @include respond(phone) {
        display: flex;
        fill: #444;
        width: 28px;
        height: 28px;
        svg {
          height: 28px;
          width: 28px;
        };
      }
    }
    &__links {
      @include flex(center, space-around, row);
      @include respond(phone) {
        position: absolute;
        width: 100vw;
        left: 0;
        background: white;
        @include flex(flex-start, flex-start, column);
        border-top: 2px solid $light-orange;
        top: -50rem;
        transition: top 0.3s linear;
      }
      &--visible {
        top: 8rem;
      }
      a {
        text-transform: uppercase;
      }
    }
    &__link {
      position: relative;
      display: flex;
      align-items: center;
      @include respond(phone) {
        padding: 20px 0;
        border-bottom: 1px dotted #F2994A;
        width: 100%;
      }
      &:first-child {
        .navbar-menu__separator {
          display: none;
          @include respond(phone) {
            display: block;
          }
        }
      }
      span {
          text-transform: uppercase;
          font-weight: 700;
          display: block;
          color: $dark-text;
          font-size: 1.8rem;
          @include respond(phone) {
              font-size: 2.4rem;
          }
          &:hover {
              transform: translateY(-0.2rem);
              transition: 300ms;
              text-shadow: 0 1.5rem 4rem rgba(black, 0.15);
              color: $dark-orange;
          }
      }  
    }
    &__separator {
          background: $dark-orange;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          margin: 0 2rem;
          @include respond(phone) {
            margin: 0 20px;
          }
        }
  }
</style>