<template>
  <article class="home">
    <section>
        <HeroPage/>
    </section>
    <section>
      <AppSection v-for="section in sections" :key="section.id" :id="section.id"
        :imageSrc="section.imageSrc" :secondary="section.secondary" :contentTitle="section.title" :contentText="section.description" :reversed="section.reversed" ></AppSection>
      <AppSection key="gallery" id="gallery" :secondary="false" contentTitle="Galeria" :reversed="false">
          <Gallery/>
      </AppSection>
    </section>
    <section id="contact">
      <Footer/>
    </section>
  </article>
</template>

<script>
// @ is an alias to /src
import HeroPage from "@/components/HeroPage/HeroPage"
import AppSection from "@/components/Section/AppSection"
import Footer from "@/components/Footer/Footer"
import Gallery from "@/components/Gallery/Gallery"
export default {
  name: 'home',
  components: {
    HeroPage,
    AppSection,
    Footer,
    Gallery
  },
  data() {
    return {
      screenWidth: window.screen.width * .9,
      modalImg: {
        src: '',
        alt: ''
      },
      sections: [
        { id: 'welcome', reversed: false, title: 'Witamy', imageSrc: '', secondary: false, description: `Sala bankietowa Grażka jest doskonałym wyborem na wszelkiego rodzaju imprezy okolicznościowe. <br/> Zapewniamy obsługę na najwyższym poziomie, przepyszną tradycyjną kuchnię, choć jesteśmy też otwarci na Państwa propozycje i oczekiwania. Gwarantujemy niezapomniane chwile spędzone w naszej sali. <br/> Sala znajduje się w Złoczewie.` },
        { id: 'offer', reversed: false, title: 'Oferta', imageSrc: 'gallery/photo1.jpg', secondary: true, description: `Do Państwa dyspozycji oddajemy salę która pomieści do 200 osób. Przed salą znajduje się parking na 40 stanowisk. Plac jest monitorowany. <br/>Za budynkiem znajduje się altana, w której oferujemy grilla na przyjęcia. Duży plac zabaw oraz miejsce do ślubów plenerowych. Dodatkowo zapewniamy 10 pokoi z łazienkami, które mogą pomieścić 35 osób.` },
        { id: 'organization', reversed: true, title: 'Organizujemy', imageSrc: 'gallery/photo2.jpg', secondary: true, description: `Zajmujemy się organizacją: <br/> - wesel, <br/> - komunii, <br/> - chrzcin, <br/> - 18-stek, <br/> - konsolacji, <br/> - imprez okolicznościowych, <br/> oferujemy również catering na imprezy plenerowe` },
        { id: 'kitchen', reversed: false, title: 'Kuchnia', imageSrc: 'gallery/photo3.jpg', secondary: true, description: `Dobra kuchnia i wyborny smak jest jednym z kluczowych aspektów naszej pracy. W naszej ofercie znajdą Państwo gamę dań kuchni tradycyjnej ale nie tylko. Każdy znajdzie coś dla siebie i swoich gości.` },
      ],
    }
  },
  methods: {
    showModal(src, alt) {
      this.modalImg.src = src
      this.modalImg.alt = alt
      this.$modal.show('hello-world');
    }
  }
}
</script>
<style lang="scss">
  p {
    line-height: 1.5;
  }
  .services {
    margin-top: 15rem;
    background-image: linear-gradient(to bottom, white, $background);
    @include respond(phone) {
      margin-top: 5rem;
    }
  }
  .images-container {
    margin-top: 5rem;
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
  }
  .contact-section {
    padding-bottom: 10rem;
  }
  .paving {
    .paragraph {
      margin-top: 5rem;
      font-size: $font-size-header;
      color: $light-text;
    }
    a {
      color: #3b5998;
    }
  }

  .modal {
    &__img {
      max-height: 90vh;
      width: inherit;
      max-width: inherit;
    }
    .v--modal-box {
      background: transparent;
      @include flex(center, center, row);
    }
  }
</style>

