<template>
  <div class="app-section-content" :class="{ 'app-section-content--secondary': secondary}" ref="contentRef">
    <h2 class="app-section-content__title" :class="{ 'app-section-content__title--secondary': secondary}">{{ title }}</h2>
    <p class="app-section-content__paragraph" 
      :class="{ 'app-section-content__paragraph--secondary': secondary}" 
      v-if="contentText"
      v-html="contentText"/>    
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'AppSectionContent',
  mounted() {
    const contentObserver = this.getNewIntersectionObserver('app-section-content--animation');
    contentObserver.observe(this.$refs['contentRef']);
  },
  props: ['secondary', 'title', 'contentText'],
  methods: {
    getNewIntersectionObserver(className) {
      return new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add(className);
            return;
          }
        });
      });
    }
  },
}
</script>


<style lang="scss" scoped>

.app-section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 150px 0;
  background: #FAFAFA;
  position: relative;
  opacity: 0;
  transition: opacity 2s linear;
  width: 100%;
  @include respond(tab-port) {
    margin: 70px 0;
  }
  @include respond(phone) {
    margin: 0 0;
    padding: 50px 0;
  }
  &--secondary {
    padding: 0;
    margin: unset;
    @include respond(phone) {
      margin: 30px 0 60px 0;
    }
  }
  &--animation {
      opacity: 1;
  }
  &__title {
    color: $dark-orange;
    font-family: 'CabinCondensed Bold';
    margin-bottom: 4rem;
    font-size: 6.4rem;
    line-height: 8rem;
    text-align: center;
    margin-top: 0;
    @include respond(tab-port) {
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    @include respond(phone) {
      margin-bottom: 2rem;
    }
    &--secondary {
      font-size: 5.6rem;
      line-height: 7.2rem;
    }
  }
  &__paragraph {
    font-size: 1.8rem;
    line-height: 2.8rem;
    letter-spacing: $default-letter-spacing;
    text-align: center;
    padding: 0 30%;
    @include respond(phone) {
      padding: 0 12%;
    }
    &--secondary {
      padding: 0 20%;
       @include respond(phone) {
        padding: 0 15%;
      }
    }
  }
}

@keyframes slideTopAndAppear {
  0% {
    //top: -20px;
    opacity: 0;
  }
  //50% {
  //  top: -50px;
  //  opacity: 0.5;
  //}
  100% {
    opacity: 1;
    //top: 0;
  }
}
</style>