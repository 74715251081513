<template>
  <div class="navbar" :class="{ 'navbar--fixed' : mobileMenuVisible}">
    <NavbarLogo :brand="brand" :logoIcon="mainIcon"/>
    <NavbarMenu :links="links" @mobileMenuToggled="mobileMenuToggle"/>
    <div class="navbar__bg"></div>
  </div>
</template>

<script>
import NavbarLogo from "@/components/Navbar/NavbarLogo";
import NavbarMenu from "@/components/Navbar/NavbarMenu";
import { mainIcon } from "./../../assets/icons/main-icon-4.ts"
export default {
  data() {
    return {
      brand: "Sala Grażka",
      mobileMenuVisible: false,
      links: [],
      mainIcon
    };
  },
  components: {
    NavbarLogo,
    NavbarMenu,
  },
  methods: {
    mobileMenuToggle(isVisible) {
      this.mobileMenuVisible = isVisible;
      this.$emit('mobileMenuToggled', isVisible);
    } 
  },
  mounted() {
    this.links = [
        {name: 'O Nas', to: "welcome"},
        {name: 'Oferta', to: "offer"},
        {name: 'Organizujemy', to: 'organization'},
        {name: 'Kuchnia', to: "kitchen"},
        {name: 'Galeria', to: "gallery"},
        {name: 'Kontakt', to: "contact"}
    ]
  }
};
</script>

<style lang="scss" >


    .navbar {
        position: relative;
        height: $navbar-height;
        padding: 0 8rem;
        font-size: 2rem;
        color: $dark-text;
        box-shadow: 0px 5px 5px -4px rgba(0,0,0,0.4);
        @include flex(center, space-between, row);
        @include respond(tab-port) {
            padding: 0 2rem;
        }
        @include respond(phone) {
            font-size: 2.4rem;
            padding: 0 20px;
        }
        &--fixed {
          @include respond(phone) {
            //position: fixed;
            //background: red;
          }
        }
        &__logo {
            height: inherit;
            z-index: 1;
            //font-family: 'lobster';
            font-family: 'CabinCondensed';
            font-size: 1.8rem;
            animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            @include flex(center, flex-end, row);
            @include respond(big-desktop) {
              font-family: 'jua';
            }
            @include respond(hd-desktop) {
              font-family: 'jua';
            }
            &__icon {
              height: 5rem;
              width: 5rem;
              margin-right: 2rem;
              @include respond(phone) {
                  // display:none;
                  height: 4rem;
              }
              svg {
                fill: $light-orange;
                stroke: $dark-orange;
                width: inherit;
                height: inherit;
              }
            }
        }

        &__bg {
          position: absolute;
          width: 100%;
          height: 100%;
          background: white;
          opacity: 0.8;
          left: 0;
          //z-index: -1;
        }

        &__label {
          @include respond(tab-port) {
            display: none;
          }
          @include respond(phone) {
            display: none;
          }
        }
    }

    @keyframes fadeInAnimation {
      0% {
          margin-left: -30px;
          opacity: 0;
      }
      25% {
          margin-left: -30px;
          opacity: 0;
      }
      100% {
          margin-left: 0;
          opacity: 1;
      }
    }
</style>
