<template>
  <footer class="footer">
    <div class="footer__header">
      <h3 class="footer__header-title">Kontakt</h3>
      <p class="footer__header-description">
        Wybierz odpowiednią dla Ciebie formę kontaktu
      </p>
    </div>
    <div class="footer__content">
      <contact-item label="e-mail:" :value="mail" :iconSrc="envelopeIcon" :clickable="false"></contact-item>
      <contact-item label="Adres:" value="ul. Burzenińska 16 <br/> 98-270, Złoczew" :iconSrc="homeIcon" :clickable="false"></contact-item>
      <contact-item label="Telefon:" :value="phone3 + ',<br/>' + phone2 + ',<br/>' + phone1" :iconSrc="phoneCallIcon" :clickable="false"></contact-item>
      <contact-item value="Facebook" :iconSrc="facebookIcon" :clickable="true" :facebookClicked="navigateToFacebook"></contact-item>
    </div>
    <p class="footer__ending">Projekt współfinansowany w ramach Osi priorytetowej XIII:REACT-EU dla Łódzkiego Regionalnego Programu Operacyjnego Województwa Łódzkiego na lata 2014-2023</p>
  </footer>
</template>

<script>
import { envelopeIcon } from '../../assets/icons/envelope-icon.ts';
import { facebookIcon } from '../../assets/icons/facebook-icon.ts';
import { homeIcon } from '../../assets/icons/home-icon.ts';
import { phoneCallIcon } from '../../assets/icons/phone-call-icon.ts';
import ContactItem from './ConctactItem.vue';
export default {
  components: {
    ContactItem
  },
  data() {
    return {
      envelopeIcon,
      facebookIcon,
      homeIcon,
      phoneCallIcon,
      phone1: '<a href="tel:+48508251806">508 251 806</a>',
      phone2: '<a href="tel:+48606742196">606 742 196</a>',
      phone3: '<a href="tel:+48607160350">607 160 350</a>',
      mail: '<a href="mailto:grazkazloczew@gmail.com">grazkazloczew@gmail.com</a>'
    }
  },
  methods: {
    navigateToFacebook() {
      window.location.href = ('https://m.facebook.com/p/Sala-Bankietowa-Gra%C5%BCka-100057127283869/');
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer {
    background: $dark-background;
    padding-bottom: 80px;
    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 68px 0 56px;
      @include respond(phone) {
        padding: 50px 0 0px
      }
    }
    &__header-title {
      color: white;
      font-size: 5.6rem;
      line-height: 7.2rem;
      margin: 0 0 20px;
    }
    &__header-description {
      color: $gray;
      font-size: 2rem;
      line-height: 2.8rem;
      letter-spacing: $default-letter-spacing;
    }
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 150px;
      color: white;
      letter-spacing: $default-letter-spacing;
      @include respond(tab-port) {
        padding: 0 50px;
      }
      @include respond(phone) {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 60px;
      }
    }
    &__ending {
      margin-top: 60px;
      text-align: center;
      padding: 0 10%;
      color: $gray;
      font-size: 1.4rem;
    }
  }
</style>