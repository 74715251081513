import { render, staticRenderFns } from "./ConctactItem.vue?vue&type=template&id=1e23c29e&functional=true&"
var script = {}
import style0 from "./ConctactItem.vue?vue&type=style&index=0&id=1e23c29e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports